import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { RootState } from '../../app/store';
import { login, logout } from '../../pages/user/user-slice';

import { BASE_API_BOOK } from '../../router/routes'
import { publicFetchBaseQuery } from './user-slice';

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_BOOK,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).userReducer.authData?.token

    // middleware will intercept 400 error and make tmp login
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
});

const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.meta?.response?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await publicFetchBaseQuery({ url: '/sign-in-tmp', method: 'POST', }, api, extraOptions);

        if (refreshResult.data) {
          api.dispatch(login(refreshResult.data))
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          //   api.dispatch(logout());
          //   window.location.href = '/login';
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default customFetchBase;
import * as React from "react";
import Box from '@mui/material/Box';
import {
  Outlet,
} from "react-router-dom";

import GlobalStyles from '@mui/material/GlobalStyles';

import Header from "../common/header/header";
import Footer from "../common/footer/footer";
/**
 *  Page wrapper which includes common components like header, navbar and footer
 */

const PageLayout = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <Header />
        <Outlet />
        <Footer />
      </Box>
    </React.Fragment >
  );
};

export default PageLayout;

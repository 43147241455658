import { createApi } from '@reduxjs/toolkit/query/react'

import { AddPractice, RouteParagraph, PersistedPractice, RoutePractice } from '@app-types';

import customFetchBase from './custom-fetch-base';
import { BOOK_API_ROUTES } from '../../router/routes';
import { generatePath } from 'react-router-dom';
import { isPersistedPractice } from '../../utils';
import { getStorageMode } from '../../pages/user/user-slice';
import { RootState } from '../../app/store';
import { saveAudio, removeAudio } from '../local-storage';
import { StorageMode } from '../../constants';
import { setActivePractice } from './practice-active-slice';

export const apiPractice = createApi({
  reducerPath: 'api-practice',
  tagTypes: ['Practice'],
  baseQuery: customFetchBase,
  endpoints: builder => ({
    addNewPractice: builder.mutation<PersistedPractice, AddPractice>({
      invalidatesTags: ['Practice'],
      async queryFn({ bookData, body }: AddPractice, _queryApi, _extraOptions, fetchWithBQ) {
        const { bookSlug, chapterSlug, paragraphSlug } = bookData
        let url
        let method
        const storageMode = getStorageMode(_queryApi.getState() as RootState)
        const formData = new FormData();

        // audio data can be saved locally
        if (storageMode === StorageMode.Server) {
          formData.append('training', body.audioData);
        }

        if (isPersistedPractice(body.practice)) {
          method = 'PUT'
          url = generatePath(BOOK_API_ROUTES.GET_PRACTICE, { bookSlug, chapterSlug, paragraphSlug, practiceId: body.practice.id })

          const { id: _id, paragraphSlug: _paragraphSlug, ...dataToSend } = body.practice
          formData.append('meta', JSON.stringify(dataToSend));
        } else {
          method = 'POST'
          url = generatePath(BOOK_API_ROUTES.LIST_PRACTICE, { bookSlug, chapterSlug, paragraphSlug })

          formData.append('meta', JSON.stringify(body.practice));
        }

        const { data, error } = await fetchWithBQ({
          url,
          method,
          body: formData,
        }) as unknown as { data: PersistedPractice, error?: Error };
        if (error) throw error;

        if (storageMode === StorageMode.Local) {
          await saveAudio({ bookData, body: { audioData: body.audioData, practiceId: data.id } })
        }

        return { data }
      },
      async onCacheEntryAdded(_arg: AddPractice, { dispatch, cacheDataLoaded }) {
        try {
          const response = await cacheDataLoaded
          dispatch(setActivePractice({ ...response.data, persisted: true }))

        } catch (err) {
          console.log('api slice error: ', err);
        }
      },
    }),
    removePractice: builder.mutation<void, RoutePractice>({
      invalidatesTags: ['Practice'],
      async queryFn(arg: RoutePractice, _queryApi, _extraOptions, fetchWithBQ) {
        const { bookSlug, chapterSlug, paragraphSlug, id } = arg
        const storageMode = getStorageMode(_queryApi.getState() as RootState)

        const rsp = await fetchWithBQ({
          url: generatePath(BOOK_API_ROUTES.GET_PRACTICE, { bookSlug, chapterSlug, paragraphSlug, practiceId: id }),
          method: 'DELETE',
        })

        if (rsp?.error) throw rsp?.error;

        if (storageMode === StorageMode.Local) {
          await removeAudio(arg)
        }

        return rsp as any
      },
    }),
    getPractices: builder.query<PersistedPractice[], RouteParagraph>({
      providesTags: ['Practice'],
      query: (params: RouteParagraph) => generatePath(BOOK_API_ROUTES.LIST_PRACTICE, params),
      transformResponse: (responseData: PersistedPractice[]) => {
        return responseData.map(el => {
          return { ...el, persisted: true }
        })
      }
    }),
  })
})

export const {
  useAddNewPracticeMutation,
  useRemovePracticeMutation,
  useGetPracticesQuery
} = apiPractice;

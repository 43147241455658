export const API_HOST = process.env.REACT_APP_API_HOST

export const BASE_API_BOOK = `${API_HOST}/api/books`
export const BASE_API_PUBLIC = `${API_HOST}/public`


export const enum ROUTES {
  HOME = "/",
  LOGIN = "/login",
  REGISTER = "/register",
  ABOUT = "/about",
  LIBRARY = "/library",
};

const BOOK_CHAPTERS = ROUTES.LIBRARY + '/:bookSlug/chapters'
const BOOK_INFO = ROUTES.LIBRARY + '/:bookSlug/info'
const BOOK_CHAPTER_PARAGRAPHS = `${BOOK_CHAPTERS}/:chapterSlug`
const BOOK_CHAPTER_PARAGRAPH_TRAINING = `${BOOK_CHAPTER_PARAGRAPHS}/paragraphs/:paragraphSlug`

export const BOOK_ROUTES = {
  BOOK_CHAPTERS,
  BOOK_INFO,
  BOOK_CHAPTER_PARAGRAPHS,
  BOOK_CHAPTER_PARAGRAPH_TRAINING,
};

export const BOOK_API_ROUTES = {
  GET_BOOK: '/:bookSlug',
  GET_BOOK_FULL: '/:bookSlug/full',

  LIST_CHAPTER: '/:bookSlug/chapter',
  GET_CHAPTER: '/:bookSlug/chapter/:chapterSlug',

  LIST_PARAGRAPH: '/:bookSlug/chapter/:chapterSlug/paragraph',
  GET_PARAGRAPH: '/:bookSlug/chapter/:chapterSlug/paragraph/:paragraphSlug',
  GET_PARAGRAPH_AUDIO: '/:bookSlug/chapter/:chapterSlug/paragraph/:paragraphSlug/audio',

  LIST_PRACTICE: '/:bookSlug/chapter/:chapterSlug/paragraph/:paragraphSlug/practice',
  GET_PRACTICE: '/:bookSlug/chapter/:chapterSlug/paragraph/:paragraphSlug/practice/:practiceId',
  GET_PRACTICE_AUDIO: '/:bookSlug/chapter/:chapterSlug/paragraph/:paragraphSlug/practice/:practiceId/audio',
}
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import localForage from "localforage";

import { RootState } from '../../app/store';

import {
  AddPracticeAudioLocally,
  GetPractice,
  LocalAudioPractice,
  RoutePractice
} from '@app-types'

export async function saveAudio({ bookData, body }: AddPracticeAudioLocally) {
  const { bookSlug, chapterSlug, paragraphSlug } = bookData
  const storageOption = {
    name: bookSlug,
    storeName: `${chapterSlug}_${paragraphSlug}`
  }

  const table = localForage.createInstance(storageOption);

  await table.setItem(body.practiceId, body.audioData)
}

export async function removeAudio(data: RoutePractice) {
  const { bookSlug, chapterSlug, paragraphSlug, id: practiceId } = data

  const table = localForage.createInstance({
    name: bookSlug,
    storeName: `${chapterSlug}_${paragraphSlug}`
  });

  await table.removeItem(practiceId)
}


export const getLocalPractice = createAsyncThunk(
  'localAudioPractice/get',
  async ({ bookData, practiceId }: GetPractice, thunkAPI) => {
    const { bookSlug, chapterSlug, paragraphSlug } = bookData

    const table = localForage.createInstance({
      name: bookSlug,
      storeName: `${chapterSlug}_${paragraphSlug}`
    });

    return table.getItem(practiceId)
  }
)

const initialState: LocalAudioPractice = {
  data: null,
  isLoading: false,
  isError: false,
  error: null,
}

const localPracticeSlice = createSlice({
  name: 'localAudioPractice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocalPractice.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getLocalPractice.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      // todo set error itself
    })
    builder.addCase(getLocalPractice.fulfilled, (state, { payload }) => {
      state.isLoading = false
      // todo fix type
      state.data = payload as any
    })
  },
})

export const selectIsLoading = (state: RootState) => {
  return state.localPracticeReducer.isLoading
}

export const selectLocalPractice = (state: RootState) => {
  return state.localPracticeReducer
}

export default localPracticeSlice.reducer;
import * as React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PageLayout from "../components/layout/page-layout";
import ReactGA from "react-ga4";

import { BOOK_ROUTES, ROUTES } from "./routes";

const RELOAD_CHUNK_RETRIED_HASH = "#reload_chunk_retried";
const TRACKING_ID = "G-41SQH8Z0FN"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const FullscreenLoader = () => <> loading aaaaaaaaaaaa </>;

const reloadRoute = (e: unknown) => {
  if (window.location.hash.includes(RELOAD_CHUNK_RETRIED_HASH)) {
    return Promise.reject(e);
  }

  window.location.hash += RELOAD_CHUNK_RETRIED_HASH;
  return { default: FullscreenLoader };
};

function usePageViews() {
  let location = useLocation()

  React.useEffect(() => {
    console.log('google send', window.location.pathname + window.location.search);

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, [location]);
}

const HomePage = React.lazy(() =>
  import("../pages/home-page/home-page").catch(reloadRoute)
);

const UserSignIn = React.lazy(() =>
  import("../pages/user/sign-in").catch(reloadRoute)
);

const UserSignUp = React.lazy(() =>
  import("../pages/user/sign-up").catch(reloadRoute)
);

const Library = React.lazy(() =>
  import("../pages/library/library").catch(reloadRoute)
);
const BookInfo = React.lazy(() =>
  import("../pages/book/info/info").catch(reloadRoute)
);
const ChapterList = React.lazy(() =>
  import("../pages/book/chapter/chapter-list").catch(reloadRoute)
);

const ParagraphList = React.lazy(() =>
  import("../pages/book/paragraph/paragraph-list").catch(reloadRoute)
);

const Practice = React.lazy(() =>
  import("../pages/book/practice/practice").catch(reloadRoute)
);

const AppRouter = (): React.ReactElement => {
  usePageViews()

  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route path={ROUTES.LOGIN} element={
          <React.Suspense fallback={<FullscreenLoader />}>
            <UserSignIn />
          </React.Suspense>
        } />

        <Route path={ROUTES.REGISTER} element={
          <React.Suspense fallback={<FullscreenLoader />}>
            <UserSignUp />
          </React.Suspense>
        } />

        <Route path={ROUTES.HOME} element={
          <React.Suspense fallback={<FullscreenLoader />}>
            <HomePage />
          </React.Suspense>
        } />
        <Route path={ROUTES.LIBRARY} element={
          <React.Suspense fallback={<FullscreenLoader />}>
            <Library />
          </React.Suspense>
        } />
        <Route path={BOOK_ROUTES.BOOK_INFO} element={
          <React.Suspense fallback={<FullscreenLoader />}>
            <BookInfo />
          </React.Suspense>
        } />
        <Route path={BOOK_ROUTES.BOOK_CHAPTERS} element={
          <React.Suspense fallback={<FullscreenLoader />}>
            <ChapterList />
          </React.Suspense>
        } />

        <Route path={BOOK_ROUTES.BOOK_CHAPTER_PARAGRAPHS} element={
          <React.Suspense fallback={<FullscreenLoader />}>
            <ParagraphList />
          </React.Suspense>
        } />

        <Route path={BOOK_ROUTES.BOOK_CHAPTER_PARAGRAPH_TRAINING} element={
          <React.Suspense fallback={<FullscreenLoader />}>
            <Practice />
          </React.Suspense>
        } />
      </Route>
    </Routes>
  );
};

export default AppRouter;

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { SectionTrainingState } from '@app-types';

var mongoObjectId = function () {
  var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
    return (Math.random() * 16 | 0).toString(16);
  }).toLowerCase();
};

export const generateNewEmptyPractice = () => {
  return {
    id: mongoObjectId(),
    wordCount: 0,
    start: 0,
    end: 0,
    recordDurationMs: 0,
    fragmentSlugStart: '',
    fragmentSlugEnd: '',
  }
}

export const getInitialState = (): SectionTrainingState => {
  return {
    playerTime: 0,
    hardSet: false,
    playing: false,
  };
}

export const practiceSlice = createSlice({
  name: 'practice-active',
  initialState: getInitialState(),
  reducers: {
    timeChanged: (state, action) => {
      const { playerTime, hardSet } = action.payload
      state.playerTime = playerTime;
      state.hardSet = hardSet;
    },
    setPlaying: (state, action) => {
      state.playing = action.payload;
    },
    setActivePractice: (state, action) => {
      state.activePractice = action.payload
    },
    removeActivePractice: () => getInitialState(),
    startPlayInterval: (state, action) => {
      state.playerTime = action.payload.start
      state.hardSet = true
      state.playInterval = action.payload

      state.playing = true
    },
  },
})

export const selectPlayerMeta = (state: RootState) => {
  return {
    playerTime: state.practiceReducer.playerTime,
    hardSet: state.practiceReducer.hardSet,
    playing: state.practiceReducer.playing,
    playInterval: state.practiceReducer.playInterval
  }
}

export const getActivePractice = (state: RootState) => {
  return state.practiceReducer.activePractice
}

export const selectPlaying = (state: RootState): SectionTrainingState['playing'] =>
  state.practiceReducer.playing

export const {
  timeChanged,
  setPlaying,
  setActivePractice,
  startPlayInterval,
  removeActivePractice,
} = practiceSlice.actions

export default practiceSlice.reducer;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_API_PUBLIC } from '../../router/routes'
import { UserLogin, UserLoginResponse, UserRegister } from '../../types'

export const publicFetchBaseQuery = fetchBaseQuery({ baseUrl: BASE_API_PUBLIC })

export const userSlice = createApi({
  reducerPath: 'api-user',
  baseQuery: publicFetchBaseQuery,
  endpoints: builder => ({
    login: builder.mutation<UserLoginResponse, UserLogin>({
      query: (body) => ({
        url: '/sign-in',
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation<UserLoginResponse, UserRegister>({
      query: (body) => ({
        url: '/sign-up',
        method: 'POST',
        body,
      }),
    })
  })
})

export const {
  useLoginMutation,
  useRegisterMutation,
} = userSlice
import React from "react";
import Navbar from "./navbar";

const Header: React.FC = (): React.ReactElement => {
  return (
    <Navbar />
  );
};

export default Header;

import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store";
import { StorageMode } from "../../constants";
import { UserLoginResponse } from "../../types";

const localStorageKey = 'user'

type State = {
  authData?: UserLoginResponse
}

const userData = localStorage.getItem(localStorageKey);
const initialState: State = {}
const defaultStorageMode = StorageMode.Local
if (userData) {
  initialState.authData = JSON.parse(userData)
}

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    login: (state, action) => {
      localStorage.setItem(localStorageKey, JSON.stringify(action.payload));
      state.authData = action.payload
    },
    logout: (state) => {
      localStorage.removeItem(localStorageKey);
      state.authData = undefined
    }
  }
})

export const getAuthData = (state: RootState): State['authData'] => state.userReducer.authData

export const getStorageMode = (state: RootState): StorageMode => {
  if (!state.userReducer.authData) {
    return defaultStorageMode
  }
  return state.userReducer.authData.practiceStorageType ?? defaultStorageMode

}

export const {
  login,
  logout,
} = userSlice.actions;

export default userSlice.reducer;
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { apiSlice, userSlice, apiPractice } from '../features/api'
import practiceReducer from '../features/api/practice-active-slice'
import userReducer from '../pages/user/user-slice'
import localPracticeReducer from '../features/local-storage/practice-slice'

export const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: ['localAudioPractice/get/fulfilled'],
      // Ignore these field paths in all actions
      // ignoredActionPaths: ['localPracticeReducer.data'],
      // // Ignore these paths in the state
      ignoredPaths: ['localPracticeReducer.data'],
    },
  })
    .concat(apiSlice.middleware, apiPractice.middleware, userSlice.middleware),
  reducer: {
    practiceReducer,
    userReducer,
    localPracticeReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [apiPractice.reducerPath]: apiPractice.reducer,
    [userSlice.reducerPath]: userSlice.reducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

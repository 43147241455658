import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  Book,
  BookPagination,
  ParagraphPagination,
  ChaptersPagination,
  RouteListChapter,
  RouteParagraph,
  RouteListParagraph,
  GetParagraphResponse,
  BookFull,
} from '@app-types';
import { BASE_API_BOOK, BOOK_API_ROUTES } from '../../router/routes';
import { generatePath } from 'react-router-dom';

const pageSize = 50

const generatePaginationUrl = (page: number) => `limit=${pageSize}&skip=${pageSize * (page - 1)}`

export const apiSlice = createApi({
  reducerPath: 'api-book',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_API_BOOK }),
  tagTypes: ['Book', 'Paragraph'],
  endpoints: builder => ({
    getBooks: builder.query<BookPagination, number | void>({
      query: (page: number) => `?${generatePaginationUrl(page)}`,
    }),
    getBook: builder.query<Book, string>({
      query: (bookSlug: string) => `${generatePath(BOOK_API_ROUTES.GET_BOOK, { bookSlug })}`,
    }),
    getBookFull: builder.query<BookFull, string>({
      query: (bookSlug: string) => `${generatePath(BOOK_API_ROUTES.GET_BOOK_FULL, { bookSlug })}`,
    }),
    geChapters: builder.query<ChaptersPagination, RouteListChapter>({
      query: ({ bookSlug, page }: RouteListChapter) => `${generatePath(BOOK_API_ROUTES.LIST_CHAPTER, { bookSlug })}?${generatePaginationUrl(page)}`,
    }),
    getParagraphs: builder.query<ParagraphPagination, RouteListParagraph>({
      query: ({ page, ...params }: RouteListParagraph) =>
        `${generatePath(BOOK_API_ROUTES.LIST_PARAGRAPH, params as RouteParagraph)}?${generatePaginationUrl(page)}`,
    }),
    getParagraph: builder.query<GetParagraphResponse, RouteParagraph>({
      query: (params: RouteParagraph) =>
        generatePath(BOOK_API_ROUTES.GET_PARAGRAPH, params),
    }),
  })
})

export const {
  useGetBookQuery,
  useGetBookFullQuery,
  useGetBooksQuery,
  useGeChaptersQuery,
  useGetParagraphsQuery,
  useGetParagraphQuery,
} = apiSlice;

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Link from '@mui/material/Link';
import { ROUTES } from '../../../router/routes';
import { useSelector } from 'react-redux';
import { getAuthData } from '../../../pages/user/user-slice';
import NavbarUser from './navbar-user';

const ResponsiveAppBar = () => {
  const authData = useSelector(getAuthData);
  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          Site name
        </Typography>
        <nav>
          <Link
            data-cy="nav-library"
            variant="button"
            color="text.primary"
            href={ROUTES.LIBRARY}
            sx={{ my: 1, mx: 1.5 }}
          >
            Library
          </Link>
          <Link
            variant="button"
            color="text.primary"
            href="#"
            sx={{ my: 1, mx: 1.5 }}
          >
            Enterprise
          </Link>
          <Link
            variant="button"
            color="text.primary"
            href="#"
            sx={{ my: 1, mx: 1.5 }}
          >
            Support
          </Link>
        </nav>

        {!authData &&
          <ButtonGroup variant="outlined" >
            <Button
              href={ROUTES.LOGIN}
            >
              Sing-in
            </Button>
            <Button
              href={ROUTES.REGISTER}
            >
              Sing-up
            </Button>
          </ButtonGroup>
        }

        {authData && authData.email && < NavbarUser {...authData} />}

      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;

import React from 'react';

import './App.css';
import AppRouter from './router/app-router';

function App() {
  return (
    <AppRouter />
  );
}

export default App;
